<template>
  <div data-v-68d7bcd4="" class="indexs">
    <div data-v-68d7bcd4="" class="web_show">
      <div data-v-68d7bcd4="" class="logo_box"><img data-v-68d7bcd4="" src="https://res.cloudinary.com/dpfshvisk/image/upload/v1711702276/tirpido_jnrlsn.png" alt=""></div><span
        data-v-68d7bcd4="">Open with an app</span>
      <div data-v-68d7bcd4="" class="download_box"><a data-v-68d7bcd4="" target="_self" href="/testapp_1.0.0.apk"
          download="app.apk" style="color: rgb(78, 78, 78); font-size: 16px;"><img data-v-68d7bcd4=""
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAABYklEQVRoQ+3a3RGCMAwA4HQJh9AldAmf6SSek5Rnl9AldAiXqJcT7zi1JW0S4CC8eRd+voQWGnSwkM0txAEG6VfSe3/lVDaEcODsj/uKVKSD7Csv5maQXuasIn/GiN1aNkZsjCTmV5t+34mx50j/BrHniD1HficMGyOkMeK938YYN5Q3WufcCQCqX1FijGfieZ4hhMe/2OxgRwwAXABgRzmRYswdAI4pBGk9MgPMIIIEwaAJMSQEGTIRhowogoyMKUIUQ0bCFCOqIMqYKkQ1RAlTjWBBhDEsBBsihGEjRCBMjAhCDFKJEUOIQgoxoghxCBEjjlCBDGBUEGqQBEYNoQr5wuDP7HoCAzibSBcldwHdEgByiyIO4LOvOkTiIinHMAglS2PGrKMiTdNgm2c2W9u2ybbRUDsIPzvX9qukE5DtSBpEOt2E41lFsDHH+msGIctFIbmvv+uYfovSNXHwYiryAhi53DPcFIGeAAAAAElFTkSuQmCC"
            alt=""></a></div>
    </div>
    <div data-v-68d7bcd4="" class="index_title">
      <p data-v-68d7bcd4="" class="top_title">Welcome Back</p>
      <p data-v-68d7bcd4="" class="bot_title">Quality Guarantee</p>
    </div>
    <div data-v-68d7bcd4="" class="swiper_box">
      <div data-v-68d7bcd4="" style="height:200px;!important" class="my-swipe van-swipe">
        <div class="van-swipe__track">
          <div class="slideshow-container">
            <div class="slides">
              <img src="../assets/1.jpg" alt="one" class="pic" name="1">
              <img src="../assets/2.jpg" alt="two" class="pic" name="2">
              <img src="../assets/3.jpg" alt="three" class="pic" name="3">
              <!-- <img src="../assets/4.jpg" alt="four" class="pic" name="4"> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-v-68d7bcd4="" class="index_list">
      <div data-v-68d7bcd4="" class="list_content">
        <ul data-v-68d7bcd4="" class="list_ul">
          <li data-v-68d7bcd4="" class="list_li" onclick="window.location.href='#/wingo'">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://res.cloudinary.com/dpfshvisk/image/upload/v1712215554/1banner_cq8cxu.jpg&quot;);">
                </div>
              </div>
              <!-- <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Joyalukkas 18k (750) Rose Gold and Solitaire
                Pendant for Girls</div> -->
              <p data-v-68d7bcd4="" class="price">1 Min Parity</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li" onclick="window.location.href='#/win'">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(https://res.cloudinary.com/dpfshvisk/image/upload/v1712215502/3banner_bh1zf4.jpg);">
                </div>
              </div>
              <!--  -->
              <p data-v-68d7bcd4="" class="price">3 Min Wingo</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li" onclick="window.location.href='#/jet'">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(https://res.cloudinary.com/dpfshvisk/image/upload/v1711006841/aviator_1_dg1ifh.png);">
                </div>
              </div>
           
              <p data-v-68d7bcd4="" class="price">Aviator</p>
            </ol>
          </li>
          <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(https://res.cloudinary.com/dpfshvisk/image/upload/v1678427029/win99x/hiloIndexEnter_ji9iuz.png);">
                </div>
              </div>
            
              <p data-v-68d7bcd4="" class="price">Coming Soon</p>
            </ol>
          </li>

          <!-- <li data-v-68d7bcd4="" class="list_li">
            <ol data-v-68d7bcd4="">
              <div data-v-68d7bcd4="" class="list_img_box">
                <div data-v-68d7bcd4="" class="list_img"
                  style="background-image: url(&quot;https://art.vitalit.app/uploads/images/16.jpg&quot;);"></div>
              </div>
              <div data-v-68d7bcd4="" class="van-multi-ellipsis--l3 info">Jewels Galaxy Designer American Diamond Gold
                Plated Bangles for Women/Girls</div>
              <p data-v-68d7bcd4="" class="price">₹ 1999.00</p>
            </ol>
          </li> -->
        </ul>
      </div>
    </div>
    <div data-v-405e9a63="" data-v-68d7bcd4="" class="footer">
      <ul data-v-405e9a63="" class="nav_foot">
        <li data-v-405e9a63="" onclick="window.location.href='#/'" class="active"><img data-v-405e9a63=""
            src="../assets/icons/home.png" alt=""><span data-v-405e9a63="">Home</span></li>
            <li data-v-405e9a63="" onclick="window.location.href='#/wingo'" class=""><img data-v-405e9a63=""
            src="../assets/icons/search.png" alt=""><span data-v-405e9a63="">Wingo</span></li>
        <li data-v-405e9a63="" class="" onclick="window.location.href='#/win'" id="game" style="display: none;"><img
            data-v-405e9a63="" src="../assets/icons/trophy.png" alt=""><span data-v-405e9a63="">Win</span></li>
        <li data-v-405e9a63="" onclick="window.location.href='#/login'" class=""><img data-v-405e9a63=""
            src="../assets/icons/avatar.png" alt=""><span data-v-405e9a63="">My</span></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  data() {
    return {
      count: 1
    }

  },
  beforeUnmount: function () {
    console.log("unmount");
    clearInterval(this.repeat);

  },
  mounted: function () {
    this.check();
    this.repeat = setInterval(() => this.slide(), 4000)
  },
  methods: {
    check() {
      if (localStorage.getItem('username') == null) {
        document.getElementById("game").style.display = "none";
      } else {
        document.getElementById("game").style.display = "";
      }
    },
    slide() {
      document.querySelectorAll(".slides img").forEach(function (photo) {
        photo.classList.remove("outleft");
        photo.classList.remove("outright");
      });
      document.querySelectorAll(".slides img")[this.count - 1].classList.remove("inleft");
      document.querySelectorAll(".slides img")[this.count - 1].classList.remove("inright");
      document.querySelectorAll(".slides img")[this.count - 1].classList.add("outleft");
      this.count = this.count + 1;
      // *** Looping document.querySelectorAll(".slides img") *** //
      if (this.count > document.querySelectorAll(".slides img").length) { this.count = 1; } // *** if reached end go to first slide *** //
      if (this.count < 1) { this.count = 4; } // *** if reached first go to last one *** //


      document.querySelectorAll(".slides img")[this.count - 1].classList.add("inleft");
      console.log(this.count);
    }
  }
}
</script>

<style>
.button,
.checkmark {
  background-color: #dedede;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(190, 190, 220, 0.2);
  color: white;
  height: 30px;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.2s;
  width: 30px;
}

.button:hover,
.checkmark:hover {
  background-color: #ccc;
}

.button:active,
.checkmark:active {
  background: #2196F3;
  box-shadow: 0 0 15px 40px rgba(220, 220, 250, 0);
}

.autoplay-controls {
  cursor: pointer;
}

.autoplay-controls:active>.checkmark {
  background: #2196F3;
  box-shadow: 0 0 15px 40px rgba(220, 220, 250, 0);
}

.slider_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 900px;
}

.slide_this.counter {
  float: right;
  margin: 0 1rem;
}

.slides {
  height: 500px;
  overflow: hidden;
  position: relative;
  width: 900px;
}

.navigation-buttons {
  display: inherit;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.slider_navigation {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}



.autoplay-sub {
  float: left;
  line-height: 30px;
  font-size: 0.7rem;
}

.checkmark {
  float: left;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 11px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.button-left:after,
.button-right:after {
  border: solid white;
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  width: 8px;
}

.button-left:after {
  left: 11px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.button-right:after {
  left: 7px;
  top: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.inright {
  -webkit-animation: goInL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  animation: goInL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  position: relative;
  z-index: 300 !important;
}

@-webkit-keyframes goInL {
  0% {
    left: -900px;
  }

  100% {
    left: 0;
  }
}

@keyframes goInL {
  0% {
    left: -900px;
  }

  100% {
    left: 0;
  }
}

.outright {
  -webkit-animation: goOutL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  animation: goOutL 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  position: relative;
  z-index: 200;
}

@-webkit-keyframes goOutL {
  0% {
    left: 0;
  }

  100% {
    left: 900px;
  }
}

@keyframes goOutL {
  0% {
    left: 0;
  }

  100% {
    left: 900px;
  }
}

.inleft {
  -webkit-animation: goInR 0.8s cubic-bezier(1, 1.4, 0.61, 0.74);
  animation: goInR 0.8s cubic-bezier(1, 1.4, 0.61, 0.74);
  position: relative;
  z-index: 300 !important;
}

@-webkit-keyframes goInR {
  0% {
    left: 900px;
  }

  50% {
    left: 400px;
  }

  100% {
    left: 0;
  }
}

@keyframes goInR {
  0% {
    left: 900px;
  }

  50% {
    left: 400px;
  }

  100% {
    left: 0;
  }
}

.outleft {
  -webkit-animation: goOutR 0.5s cubic-bezier(1, 1.59, 0.61, 0.74);
  animation: goOutR 1.0s cubic-bezier(1, 1.59, 0.61, 0.74);
  position: relative;
  z-index: 200;
}

@-webkit-keyframes goOutR {
  0% {
    left: 0;
  }

  100% {
    left: -900px;
  }
}

@keyframes goOutR {
  0% {
    left: 0;
  }

  100% {
    left: -900px;
  }
}

.pic {
  left: 0;
  height: 35%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 54%;
}

.pic:first-child {
  z-index: 100;
}
</style>
